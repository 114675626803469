.footer {
    background-color: #005B82;
    padding: 15px 0;
    text-align: center;
    height: fit-content;

    .social-medias {
        width: 100%;
        height: fit-content;
        list-style: none;
        margin: 10px 0 20px;
        padding: 0;

        li {
            display: inline-flex;
            background-color: #FFF;
            width: 40px;
            height: 40px;
            border-radius: 100%;
            justify-content: center;
            align-items: center;
            font-size: 23px;
            margin: 0 7px;
            cursor: pointer;
            
            svg {
                color: #005B82;
            }
        }
    }

    .copyright-info {
        display: block;
        font-weight: lighter;
        color: white;
    }
}
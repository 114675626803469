.section-banner {
    width: 100%;
    background: linear-gradient(90deg, rgba(0,0,0,0.6), rgba(0,0,0,0.6)), url('../../assets/section-banner-1.jpg') center center;
    background-size: cover;
    height: 310px;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    font-weight: 500;
    text-align: center;
}
.banner-wrapper {
    background: url('../../assets/banner.jpg') center center;
    background-size: cover;
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
    overflow-y: hidden;

    .shadow-bar {
        transform: rotate(60deg);
        background-color: #00000081;
        width: 100%;
        height: 200%;
        position: absolute;
        left: 0;
        top: calc(sin(60deg) / sin(90deg) * (100vh - 600px) / 2);
        left: -40vw;
        border: 4px solid #0086bf8d;
    }

    .banner-hero {
        position: absolute;
        bottom: 420px;
        right: 20px;
        display: block;
        width: fit-content;
        padding: 10px 20px;
        margin-top: 20px;
        border-radius: 10px;

        .title {
            font-weight: 500;
            color: white;
            font-size: 30px;
            float: left;
            margin-top: 10px;
        }

        button {
            float: right;
            background-color: transparent;
            border: 2px solid #FFFFFF;
        }
    }

    @media (max-width: 750px) {
        justify-content: center;

        .banner-info {
            text-align: center;
            left: 0 !important;
            position: relative !important;
        }

        .banner-info .banner-title {
            width: 90% !important;
            margin: 0 auto;
        }

        .banner-info .banner-description {
            width: 70% !important;
            margin: 0 auto;
        }
    }

    @media (max-width: 400px) {
        background: linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.4)), url('../../assets/banner.jpg') center center;
        background-size: cover;

        .banner-info .banner-title {
            font-size: 30px !important;
        }

        .banner-info .banner-description {
            font-size: 18px !important;
        }
    }

    @media (max-width: 2000px) {
        .shadow-bar {
            top: -10vh;
            left: -40vw;
        }
    }

    @media (max-width: 1250px) {
        .shadow-bar {
            width: 100%;
            height: 100%;
            top: unset;
            left: unset;
            border: 0;
            transform: rotate(0deg);
        }
    }

    .banner-info {
        color: white;
        position: absolute;
        left: 80px;
        width: 800px;
        
        span {
            display: block;

            &.banner-title {
                font-weight: bold;
                font-size: 50px;
                width: 80%;
            }

            &.banner-description {
                font-size: 20px;
                width: 50%;
            }
        }

        button {
            margin-top: 20px;
            padding: 15px 40px;
            box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
            background-color: #007427;
        }
    }
}
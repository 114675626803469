$header-height: 70px;

.header-wrapper {
    height: $header-height;
    width: 100%;
    position: relative;
    z-index: 11;

    @media (max-width: 600px) {
        .header-items li:not(.logo) {
            display: none;
        }

        .header-items li.logo {
            margin: 0 auto !important;
            float: none !important;
            display: block;
            width: fit-content;
        }
    }

    .header-block {
        height: $header-height;
        display: block;
        width: 100%;
    }

    .header {
        height: $header-height;
        position: fixed;
        top: 0;
        width: 100%;
        background-color: white;
        box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
    }

    .header-items {
        list-style: none;
        padding: 0;
        margin: 0 auto;
        height: 100%;
        width: 100%;
        font-size: 17px;

        li {
            float: right;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 15px;
            cursor: pointer;

            &:hover {
                background-color: #dedede;
            }

            &.special {
                background-color: #0079B1;
                color: white;
                font-weight: 500;
            }

            &.active {
                margin-top: 3px;
                height: calc(100% - 6px);
                border-bottom: 5px solid #0098DF;
                background-color: #dedede84;
            }

            &.logo {
                float: left;
                padding: 0;
                margin-left: 30px;
                cursor: unset;

                img {
                    height: calc(100% - 26px);
                    padding: 13px 0;
                }
            }
        }
    }
}
.colored-container {
    height: fit-content;
    padding: 30px 0 50px;
    margin: 40px 0;
    -webkit-box-shadow: 0px 0px 13px 4px rgba(61, 61, 61, 0.3);
    -moz-box-shadow: 0px 0px 13px 4px rgba(61, 61, 61, 0.3);
    box-shadow: 0px 0px 13px 4px rgba(61, 61, 61, 0.3);
    position: relative;
    overflow: hidden;

    .title {
        display: block;
        font-weight: 500;
        font-size: 30px;
        margin: 0 auto 7px;
        width: fit-content;
        color: white;
    }

    .separator {
        margin: 10px auto 30px;
        width: 60px;
        height: 5px;
        background-color: rgb(215, 215, 215);
        border-radius: 10px;
    }
}
.image-text-container {
    height: fit-content;
    padding: 30px 0;
    -webkit-box-shadow: 0px 0px 13px 4px rgba(61, 61, 61, 0.3);
    -moz-box-shadow: 0px 0px 13px 4px rgba(61, 61, 61, 0.3);
    box-shadow: 0px 0px 13px 4px rgba(61, 61, 61, 0.3);
    position: relative;
    overflow: hidden;

    .corner-1 {
        width: 200px;
        height: 100px;
        background-color: #0077af;
        position: absolute;
        top: -60px;
        right: -70px;
        transform: rotate(45deg);
        z-index: 1;
    }

    .corner-2 {
        width: 400px;
        height: 60px;
        background-color: #005f8c;
        position: absolute;
        top: -80px;
        right: -80px;
        transform: rotate(45deg);
    }

    &.inverse {
        .image-wrapper {
            margin: 0 60px 0 0 !important;
        }
    }

    &.normal {
        background-color: #FFF;
    }

    &.center {
        .text-wrapper {
            text-align: center;

            .description {
                text-align: center !important;
            }

            button {
                margin: 0 auto;
                display: block;
            }
        }
    }

    @media (max-width: 800px) {
        margin-bottom: 20px !important;

        .image-text-item {
            text-align: center;
            display: flex;
            flex-direction: column-reverse;
        }

        &.inverse {
            .image-text-item {
                display: flex;
                flex-direction: column;
            }
        }

        .image-text-item .image-wrapper {
            display: block !important;
            margin: 0 auto 20px !important;

            &.icon {
                display: flex !important;
            }
        }

        .image-text-item .text-wrapper {
            display: block !important;
            margin: 0 auto !important;
            width: 90% !important;

            button {
                margin: 0 auto;
                display: block;
            }

            .separator {
                margin: 10px auto 30px !important;
            }
        }
    }

    .image-text-item {
        width: 90%;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    
        .image-wrapper {/*  */
            background: url('../../assets/about-us-1.png') center center;
            background-size: cover;
            height: 300px;
            width: 300px;
            border-radius: 100%;
            margin: 0 0 0 60px;
            display: flex;
            justify-content: center;
            align-items: center;

            svg {
                color: white;
                font-size: 120px;
            }
        }
    
        .text-wrapper {
            width: calc(80% - 200px);
    
            .title {
                display: block;
                font-weight: 500;
                font-size: 25px;
                margin-bottom: 7px;
            }

            .separator {
                margin: 10px 0px 30px;
                width: 60px;
                height: 5px;
                background-color: #0099FF;
                border-radius: 10px;
            }
    
            .description {
                display: block;
                font-size: 17px;
                text-align: justify;
                line-height: 1.5em;
            }

            button {
                margin-top: 20px;
                box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
                background: #00608d;
            }
        }
    }
}
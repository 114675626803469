.vision-card-wrapper {
  display: inline-block;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: white;
  padding: 20px;
  height: 300px;
  margin: 0px 20px;
  border-radius: 10px;

  .icon {
    font-size: 100px;
  }

  .description {
    text-align: center;
    width: 75%;
    margin-top: 20px;
    font-size: 18px;
  }

  hr {
    width: 60%;
    margin: 20px 0px 10px 0px;
    background-color: #ccc;
    border: 0;
  }
}
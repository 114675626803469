.integrity-component {
    width: calc(33.333% - 1px);
    text-align: center;
    height: 100%;
    float: left;
    vertical-align: top;
    align-self: flex-start;

    .icon-circle {
        margin: 0 auto;
        background-color: #0079B1;
        width: 80px;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        padding: 20px;
        font-size: 70px;
        border-radius: 100%;
    }

    .info {
        margin-top: 20px;

        span {
            display: block;

            &.title {
                font-weight: bold;
                font-size: 18px;
                margin-bottom: 5px;
            }

            &.description {
                text-align: center;
                width: 70%;
                margin: 0 auto;
            }
        }
    }
}
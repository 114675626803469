.clients-wrapper {
    list-style: none;
    padding: 0;
    margin: 0;
    height: 80px;
    width: 100%;
    text-align: center;
    background-color: #EFEFEF;
    overflow-y: auto;

    @media (max-width: 900px) {
        overflow-x: scroll;
        height: fit-content;

        li img {
            height: 36px !important;
        }
    }

    li {
        height: calc(100% - 44px);
        padding: 22px 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        filter: grayscale(0.3);
        transition: filter 0.2s ease;
        display: inline-block;
        margin: 0 auto;

        &:hover {
            cursor: pointer;
            filter: grayscale(0)
        }

        img {
            height: 100%;
        }
    }
}
.full-image-container {
  height: fit-content;

  &.inverse {
      .image-wrapper {
          margin: 0 60px 0 0 !important;
      }
  }

  &.normal {
      background-color: #FFF;
  }

  &.center {
      .text-wrapper {
          text-align: center;

          .description {
              text-align: center !important;
          }

          button {
              margin: 0 auto;
              display: block;
          }
      }
  }

  @media (max-width: 700px) {
      iframe {
        width: 100% !important;
        height: 300px !important;
        margin-bottom: 20px;
        border: 0 !important;
      }
    
      .image-text-item {
          text-align: center;
          display: flex;
          flex-direction: column-reverse;
      }

      &.inverse {
          .image-text-item {
              display: flex;
              flex-direction: column;
          }
      }

      .image-text-item .image-wrapper {
          display: block !important;
          margin: 0 auto 20px !important;
          width: 100% !important;
          height: 300px !important;
          border: 0 !important;

          &.icon {
              display: flex !important;
          }
      }

      .image-text-item .text-wrapper {
          display: block !important;
          margin: 0 auto 30px !important;
          width: 90% !important;

          button {
              margin: 0 auto;
              display: block;
          }
      }
  }

  .image-text-item {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
  
      .image-wrapper, iframe {
          background: url('../../assets/about-us-1.png') center center;
          background-size: cover;
          height: 500px;
          width: calc(40% - 4px);
          display: flex;
          justify-content: center;
          align-items: center;
          border-left: 4px solid #ccc;

          svg {
              color: white;
              font-size: 120px;
          }
      }

      iframe {
        border: 0;
        border-left: 4px solid #ccc;
      }
  
      .text-wrapper {
          width: 60%;

          button {
            margin: 30px auto 0px !important;
            display: block;
          }
  
          .title {
              display: block;
              font-weight: 500;
              font-size: 24px;
              margin-bottom: 7px;
              width: 60%;
              margin: 0 auto;
              text-align: center;
          }

          .separator {
            margin: 10px auto 30px;
            width: 60px;
            height: 5px;
            background-color: #0099FF;
            border-radius: 10px;
          }
  
          & > .description {
              display: block;
              font-size: 17px;
              text-align: justify;
              width: 50%;
              margin: 0 auto;
              line-height: 1.5em;
              padding-bottom: 20px;
          }

          button {
              margin-top: 20px;
              box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
              background: #00608d;
          }
      }
  }

  @media (max-width: 1100px) {
    .image-text-item > .text-wrapper > .description {
        width: 80%;
    }
  }

  @media (max-width: 700px) {
    .image-text-item > .text-wrapper > .description {
        width: 90%;
    }
  }
}
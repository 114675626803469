.subtitle-container {
    text-align: center;
    height: fit-content;
    padding: 0 0 30px;
    font-size: 17px;

    @media (max-width: 450px) {
        .text {
            text-align: justify;
        }
    }

    .text {
        width: 60%;
        display: block;
        margin: 0 auto;
    }
}
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

::-webkit-scrollbar-track {
	border-radius: 5px;
	background-color: #fff;
}

::-webkit-scrollbar {
	width: 5px;
	background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb {
    border-radius: 3px;
	background-color: #aaa;
}

body, html {
    width: 100%;
    padding: 0;
    height: 100vh;
    margin: 0;
    font-family: 'Roboto', sans-serif;
    overflow-x: hidden;
}

* {
    font-family: 'Roboto', sans-serif;
}

button {
    background: #005B82;
    border-radius: 12px;
    border: 0;
    padding: 10px 20px;
    color: white;
    font-size: 15px;
    cursor: pointer;

    &.secondary {
        background: #1f9c2d;
    }

    &.center {
        display: block;
        margin: 0 auto;
    }
}

hr {
    width: 75%;
    margin: 20px auto;
    border: 0;
    height: 1px;
    background-color: #eee;
}

a {
    color: #005177;
    font-weight: 500;
    text-decoration: none;
}

input, select, textarea {
    border: 1px solid #666;
    padding: 12px 10px;
    border-radius: 3px;
    background-color: white;
    outline: none;
}

select {
    padding: 12px 10px;
}
.contact-form {
    width: 50%;
    margin: 0 auto;
    text-align: center;
    padding: 0 0 60px;

    @media (max-width: 830px) {
        width: 80%;
    }

    @media (max-width: 400px) {
        padding: 0 0 70px;

        #phone, #email {
            width: calc(100% - 26px) !important;
        }
    }

    input, select, textarea {
        margin: 5px;
    }

    #complete-name {
        width: calc(100% - 32px);
    }

    #phone, #email {
        width: calc(50% - 32px);
    }

    #type {
        width: calc(100% - 9px);
    }

    #message {
        width: calc(100% - 31px);
        height: 100px;
        resize: none;
    }

    button {
        margin-top: 10px;
    }
}

.message-sent {
    font-size: 25px;
    font-weight: 500;
}
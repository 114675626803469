.banner-wrapper {
    height: calc(100vh - 160px);
}

.vertical-line {
    height: 200px;
    width: 2px;
    background-color: #ddd;
    float: left;
}

.integrity-container {
    padding: 30px 0;
    height: fit-content;

    .integrity-component-list {
        width: 100%;
        height: fit-content;
        overflow-y: auto;
        list-style: none;
        padding: 0;
        margin: 0;
        height: fit-content;
        overflow-y: auto;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        
        hr {
            display: none;
        }

        @media (max-width: 780px) {
            display: block;

            hr {
                display: block;
            }

            .vertical-line {
                display: none;
            }

            .integrity-component {
                display: block;
                float: none;
                width: 90%;
                margin: 0 auto 40px;
            }
        }
    }
}

.subtitle-container {
    margin-bottom: 20px;
}

.services-list {
    margin: 0 auto;
    text-align: center;
    width: 80%;
    margin-bottom: 50px !important;
}

.values-slider {
    margin-top: 50px !important;
    display: block;
    width: 60%;
    margin: 0 auto;
}

@media (max-width: 1000px) {
    .values-slider {
        width: 80%;
    }
}
.title-container {
    height: 70px;
    width: fit-content;
    margin: 40px auto 0;
    text-align: center;
    font-size: 28px;
    font-weight: 500;
    color: #000;

    .title {
        display: block;
    }

    .bottom-bar {
        display: block;
        width: 40%;
        height: 5px;
        border-radius: 10px;
        background-color: #0098DF;
        margin: 10px auto;
    }
}
.service-component {
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
    margin: 20px;
    border-radius: 5px;
    display: inline-block;
    vertical-align: top;

    .image {
        width: 100%;
        height: 240px;
        background: url('../../assets/service-1.png') center center;
        background-size: cover;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }

    .info {
        padding: 10px;
        min-height: 240px;

        .separator {
            margin: 10px auto 20px;
            width: 60px;
            height: 5px;
            background-color: #0099FF;
            border-radius: 10px;
        }

        span {
            display: block;
            text-align: center;

            &.title {
                font-weight: 500;
                font-size: 22px;
                margin-bottom: 10px;
                margin-top: 15px;
            }

            &.description {
                font-size: 15px;
                width: 80%;
                margin: 0 auto;
            }
        }
    }
}